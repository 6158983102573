import React, { createContext, useContext } from "react";
import useGroundStationsExternal, { GroundStation } from "../helpers/useGroundStations";

const GroundStationContext = createContext<GroundStation[] | undefined>(undefined);

export function useGroundStations() {
    return useContext(GroundStationContext);
}

export function GroundStationProvider({ children }: { children: React.ReactNode }) {
    const groundStations = useGroundStationsExternal();
    return (
        <GroundStationContext.Provider value={groundStations}>
            {children}
        </GroundStationContext.Provider>
    );
}
