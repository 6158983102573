import { useLeanspaceAPI } from "@leanspace/js-client/dist/react";
import { useEffect, useState } from "react";

export type GroundStation = {
    id: string
    description: string
    name: string
    country: string
    latitude: number
    longitude: number
    elevation: number
};

export default function useGroundStations(refresh?: number) {
    const [groundStations, setGroundStations] = useState<GroundStation[]>();
    const { nodes: apiNodes } = useLeanspaceAPI();

    const refreshAll = async () => {
        const nodes = await apiNodes.getAll({ kinds: ['GROUND_STATION'] });
        Promise.all(
            nodes.content.map(async (node) => {
                const completeNode = await apiNodes.get<"GROUND_STATION">(node.id!);
                return {
                    id: completeNode.id!,
                    description: completeNode.description,
                    name: completeNode.name,
                    country: completeNode.tags?.find((t) => t.key === 'country')?.value ?? '?',
                    latitude: +completeNode.latitude!,
                    longitude: +completeNode.longitude!,
                    elevation: +completeNode.elevation!,
                };
            })
        ).then(setGroundStations);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { refreshAll(); }, []);

    return groundStations;
}
