import { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useSlack } from '../providers/SlackProvider';
import NotificationBlock from './NotificationBlock';

export default function NotificationPane({
    visible,
    onHide,
    onHiddenReception,
}: {
    visible: boolean,
    onHide: () => void,
    onHiddenReception: () => void,
}) {
    const [actualVisible, setActualVisible] = useState(visible);
    const lastMessages = useSlack();

    useEffect(() => {
        if (visible) setActualVisible(true)
        else {
            setTimeout(() => {
                if (!visible) setActualVisible(false)
            }, 300);
        }
    }, [visible])

    useEffect(() => {
        // If received something, it's not the first reception, and hidden!
        if (!visible) {
            onHiddenReception();
        }
    }, [lastMessages.length, onHiddenReception, visible]);

    return (
        <div
            style={{
                transition: 'backdrop-filter 0.3s',
                position: 'fixed',
                inset: 0,
                pointerEvents: visible ? 'visible' : 'none',
                backdropFilter: visible ? 'brightness(0.3)' : 'none',
                zIndex: 100,
            }}
            onClick={onHide}
        >
            <div
                className="card p-4"
                style={{
                    transition: 'all 0.3s',
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    right: visible ? 0 : '-30%',
                    minWidth: '30%',
                    maxWidth: '30%',
                    overflow: 'auto',
                }}
                onClick={(e) => e.stopPropagation()}
            >
                {actualVisible && (
                    <>
                        <div className="w-100 d-flex flex-row-reverse pb-3">
                            <button className="btn px-2" onClick={onHide}>
                                <MdClose className="fs-24" />
                            </button>
                        </div>
                        <div className="fs-16 fw-600 mb-2">
                            Notifications
                        </div>

                        {lastMessages && lastMessages.map((msg) => (
                            <NotificationBlock message={msg} showNavigation onNavigate={onHide} />
                        ))}
                    </>
                )}
            </div>
        </div >
    )
}
