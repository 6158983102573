import { useLeanspaceAPI } from "@leanspace/js-client/dist/react";
import { Property } from "@leanspace/js-client/dist/types/Assets";
import { CommandDefinitionExtended, CommandQueue } from "@leanspace/js-client/dist/types/Commands";
import { Widget } from "@leanspace/js-client/dist/types/Dashboards";
import { Tag } from "@leanspace/js-client/dist/types/Generic";
import { Metric } from "@leanspace/js-client/dist/types/Metrics";
import { Monitor } from "@leanspace/js-client/dist/types/Monitors";
import { useCallback, useEffect, useState } from "react";
import { useInterval } from "usehooks-ts";

export type Satellite = {
    id: string
    description: string
    name: string
    status?: 'ok' | 'nok',
    monitors: Monitor[]
    properties: Property[]
    metrics: Metric[]
    widgets: Widget[]
    commandQueue?: CommandQueue,
    commandDefinitions: CommandDefinitionExtended[],
};

const alphaSort = <T extends { name: string }>(a: T, b: T) => a.name.localeCompare(b.name);

export default function useSatellites(refresh?: number) {
    const [satellites, setSatellites] = useState<Satellite[]>();
    const [lastRefresh, setLastRefresh] = useState<Date>();
    const {
        nodes: apiNodes,
        properties: apiProperties,
        monitors: apiMonitors,
        metrics: apiMetrics,
        widgets: apiWidgets,
        commandQueues: apiCommandQueues,
        commandDefinitions: apiCommandDefinitions,
    } = useLeanspaceAPI();

    const refreshAll = useCallback(async () => {
        apiNodes.getAll({ kinds: ["SATELLITE"] }).then((nodes) => (
            nodes.content.map((n) => ({
                id: n.id,
                name: n.name,
                description: n.description,
                monitors: [],
                properties: [],
                metrics: [],
                widgets: [],
                commandQueue: undefined,
                commandDefinitions: []
            }) as Satellite).sort(alphaSort)
        )).then(async (sats) => {
            // Nicer UX to have the satellites earlier even if incomplete.
            if (satellites === undefined) {
                setSatellites(sats);
            }

            const [
                properties,
                monitors,
                metrics,
                widgets,
                commandQueues,
                commandDefinitions,
            ] = await Promise.all([
                apiProperties.getAll({ nodeIds: sats.map((s) => s.id) }),
                apiMonitors.getAll({}),
                apiMetrics.getAll({ size: 1000 }),
                apiWidgets.getAll({}),
                apiCommandQueues.getAll({}),
                apiCommandDefinitions.getAll({ withArgumentsAndMetadata: true }),
            ]);

            sats.forEach((satellite) => {
                const filterForTag = (obj: { tags?: Tag[] }) => obj.tags && obj.tags.find((tag) => tag.key === 'satellite' && tag.value === satellite.name);

                const matchingProperties = properties.content.filter((p) => (p as any).nodeId === satellite.id);
                const matchingMonitors = monitors.content.filter(filterForTag);
                const matchingMetrics = metrics.content.filter(filterForTag);
                const matchingWidgets = widgets.content.filter(filterForTag)
                const matchingCommandQueue = commandQueues.content.find((cq) => cq.assetId === satellite.id);
                const matchingCommandDefinitions = commandDefinitions.content.filter((cd) => cd.nodeId === satellite.id);

                const status = matchingMonitors.filter((m) => m.state === 'TRIGGERED').length === 0 ? 'ok' : 'nok';

                satellite.properties = matchingProperties.sort(alphaSort);
                satellite.monitors = matchingMonitors.sort(alphaSort);
                satellite.metrics = matchingMetrics.sort(alphaSort);
                satellite.widgets = matchingWidgets.sort(alphaSort);
                satellite.commandQueue = matchingCommandQueue;
                satellite.commandDefinitions = matchingCommandDefinitions.sort(alphaSort);
                satellite.status = status;
            });

            setSatellites(sats);
            //console.log(sats);
        });
    }, [apiCommandDefinitions, apiCommandQueues, apiMetrics, apiMonitors,apiNodes, apiProperties, apiWidgets, satellites]);

    useEffect(() => {
        // If last refresh less than 5 seconds ago, cancel
        if (lastRefresh && (lastRefresh.getTime() - new Date().getTime()) < 5000) {
            return;
        }
        setLastRefresh(new Date());
        refreshAll();
    }, [
        apiProperties,
        apiNodes,
        apiMonitors,
        apiMetrics,
        apiWidgets,
        apiCommandDefinitions,
        apiCommandQueues,
        refresh,
        lastRefresh,
        refreshAll
    ]);

    useInterval(refreshAll, 20000);

    return satellites;
}
