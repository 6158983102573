import { Auth } from "@aws-amplify/auth";
import { FormEvent, useContext, useState } from "react";
import { MdLock } from "react-icons/md";
import UserContext from "./UserContext";

export default function Login() {
    const { login } = useContext(UserContext);
    const [hasLoginError, setLoginError] = useState(false);

    const attemptLogin = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.target as any);

        try {
            setLoginError(false);
            const username = formData.get("username") as string;
            const password = formData.get("password") as string;

            const user = await Auth.signIn(username, password);
            const {
                accessToken: { jwtToken }
              } = user.signInUserSession;
            login(jwtToken);

        } catch (err) {
            setLoginError(true);
        }
    };

    return (
        <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
            <div>
                <div className="w-100 h2 text-center"><MdLock /> </div>
                <h1 className="text-center">Sign In</h1>
                <form className="d-flex flex-column" noValidate onSubmit={attemptLogin}>
                    <label htmlFor="username" className="form-label">
                        Username
                        <input type="text" className="form-control" name="username" id="username" />
                    </label>
                    <label htmlFor="password" className="form-label">
                        Password
                        <input type="password" className="form-control" name="password" id="password" />
                    </label>
                    <input type="submit" className="btn btn-bordered" value="Sign in" />
                    {hasLoginError && <div className="text-error">Wrong login details.</div>}
                </form>
            </div>
        </div>
    );
};

