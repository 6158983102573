import React, { createContext, useContext, useEffect, useState } from "react";
import { useInterval } from "usehooks-ts";

const SLACK_TOKEN = '***REMOVED***';
const CHANNEL_ID = 'C03QGJXG19A';

type Message = {
    type: 'message',
    subtype: string,
    user: string,
    text: string,
    content: string,
    ts: string,
};

type MemberData = {
    id: string,
    name: string,
    real_name: string,
}

const makeFormBody = (data: Record<string, string>) => {
    return Object.entries(data).map(([key, value]) => {
        var encodedKey = encodeURIComponent(key);
        var encodedValue = encodeURIComponent(value);
        return `${encodedKey}=${encodedValue}`;
    }).join('&');
}

export type SlackMessage = {
    type: 'user'
    username: string
    content: string
    ts: number
} | {
    type: 'bot'
    monitorName: string
    content: string
    ts: number
}

const msgRegex = /The monitor (.*) has been triggered./

const SlackContext = createContext<SlackMessage[]>([]);

export function SlackProvider({ children }: { children: React.ReactNode }) {
    const [lastRefresh, setLastRefresh] = useState<Date>();
    const [lastMessages, setLastMessages] = useState<SlackMessage[]>([]);
    const [storedUsernames, setStoredUsernames] = useState<Record<string, string>>();

    useEffect(() => {
        fetch(
            `https://slack.com/api/users.list`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'POST',
            body: makeFormBody({
                token: SLACK_TOKEN
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (!response.ok) {
                    //console.log(response);
                    return;
                }
                setStoredUsernames(
                    Object.fromEntries(
                        (response.members as MemberData[]).map((member) => [member.id, member.real_name])
                    )
                )
            })
    }, [])

    useInterval(() => {
        if (!storedUsernames) return;

        let oldest;
        if (!lastRefresh) {
            oldest = new Date();
            oldest.setMinutes(oldest.getMinutes() - 30);
        } else {
            oldest = lastRefresh;
        }
        const latest = new Date();
        setLastRefresh(latest);
        fetch(
            `https://slack.com/api/conversations.history?channel=${CHANNEL_ID}&oldest=${oldest.getTime() / 1000}&latest=${latest.getTime() / 1000}`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'POST',
            body: makeFormBody({
                token: SLACK_TOKEN
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (!response.ok) {
                    console.log(response);
                    return;
                }
                const newMessages = (response.messages as Message[])
                    .filter((msg) => msg.subtype === 'bot_message' || msg.subtype === undefined)
                    .map((msg) => (msg.subtype === 'bot_message' ? {
                        type: 'bot',
                        ts: +msg.ts,
                        monitorName: msg.text.match(msgRegex)?.at(1) ?? msg.text,
                        content: msg.text.split('\n')[1],
                    } : {
                        type: 'user',
                        ts: +msg.ts,
                        username: storedUsernames[msg.user] ?? msg.user,
                        content: msg.text,
                    }) as SlackMessage)
                setLastMessages([...newMessages, ...(lastMessages ?? [])])
            })
    }, 5000);

    return (
        <SlackContext.Provider value={lastMessages}>
            {children}
        </SlackContext.Provider>
    );
}

export function useSlack() {
    return useContext(SlackContext);
}
