import { useState } from 'react';
import { MdNotificationsNone, MdOutlinePersonOutline } from 'react-icons/md';
import NotificationPane from './NotificationPane';

export default function TopBar() {
    const [showNotifications, setShowNotifications] = useState(false);
    const [unreadNotification, setUnreadNotifications] = useState(false);

    return (
        <nav className="w-100 d-flex p-2 pt-3 ps-0 align-items-center">
            <div className="text-center px-3 fs-2 logo-font" style={{ userSelect: 'none', minWidth: '100px' }}>
                EO
            </div>
            <div className="me-auto h5 ps-2">Mission 2023</div>
            <div className="mt-n2">
                <button className="btn px-2" style={{ position: 'relative' }} onClick={() => {
                    setShowNotifications(true);
                    setUnreadNotifications(false);
                }}>
                    <MdNotificationsNone className="fs-3" />
                    {unreadNotification && (
                        <div style={{
                            backgroundColor: 'red',
                            minWidth: '8px',
                            minHeight: '8px',
                            position: 'absolute',
                            borderRadius: '5px',
                            top: '8px',
                            right: '10px',
                        }} />
                    )}
                </button>
                <NotificationPane
                    visible={showNotifications}
                    onHide={() => setShowNotifications(false)}
                    onHiddenReception={() => setUnreadNotifications(true)}
                />
            </div>
            <div className="mt-n2">
                <button className="btn px-2">
                    <MdOutlinePersonOutline className="fs-3" />
                </button>
            </div>
        </nav>
    );
}
