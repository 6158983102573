import { Amplify } from "@aws-amplify/core";
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import SideBar from './components/SideBar';
import TopBar from './components/TopBar';
import UtahRoutes from './Router';
import { LeanspaceProvider } from "@leanspace/js-client/dist/react";
import { RestClientOptions } from '@leanspace/js-client/dist/RestClient';
import { SatellitesProvider } from './providers/SatellitesProvider';
import { GroundStationProvider } from './providers/GroundStationProvider';
import en from 'javascript-time-ago/locale/en.json'
import TimeAgo from 'javascript-time-ago';
import { RefProvider } from './providers/RefProvider';
import Login from './components/Login';
import { Auth } from "@aws-amplify/auth";
import { SlackProvider } from "./providers/SlackProvider";
import UserContext from "./components/UserContext";

const LeanspaceProvider18 = LeanspaceProvider as React.FC<PropsWithChildren<{ clientOptions: RestClientOptions }>>

export const getToken = async () => {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: false,
  });

  const {
    accessToken: { jwtToken },
  } = user.signInUserSession;
  return jwtToken;
};

TimeAgo.addDefaultLocale(en)

function App() {
  const parentRef = useRef<HTMLDivElement>(null);
  const [isAmplifyReady, setAmplifyReady] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [token, updateToken] = useState<string>();

  useEffect(() => {
    fetch('https://api.leanspace.io/tenants-repository/tenants/key/eoltd')
      .then((response) => response.json())
      .then((data) => {
        Amplify.configure({
          Auth: {
            ...data.authInfo,
            userPoolWebClientId: data.authInfo.clientAppId,
            region: 'eu-central-1',
            accountId: data.tenantKey
          }
        })
      })
      .then(async () => {
        setAmplifyReady(true)
        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser.signInUserSession) {
          const {
            accessToken: { jwtToken }
          } = currentUser.signInUserSession;
          updateToken(jwtToken);
          setLoggedIn(true);
        }
      })
  }, []);

  const onLogin = (token: string) => {
    updateToken(token);
    setLoggedIn(true);
  };

  const contextValue = {
    token,
    login: onLogin,
    isLoggedIn,
    env: "PROD"
  };

  return isAmplifyReady ? (
    <div>
      <RefProvider key="refProvider" parentRef={parentRef}>
        <UserContext.Provider value={contextValue}>
          <LeanspaceProvider18 clientOptions={{ getToken }}>
            {isLoggedIn ? (
              <SlackProvider>
                <SatellitesProvider>
                  <GroundStationProvider>
                    <BrowserRouter >
                      <div className="d-flex flex-column container-fluid p-0 pe-5 pb-2" ref={parentRef}>
                        <TopBar />
                        <main className="d-flex flex-row">
                          <SideBar />
                          <React.Suspense fallback={null}>
                            <div className="d-flex flex-column container-fluid p-0 flex-grow-1">
                              <UtahRoutes />
                            </div>
                          </React.Suspense>
                        </main>
                      </div>
                    </BrowserRouter>
                  </GroundStationProvider>
                </SatellitesProvider>
              </SlackProvider>
            ) : (
              <Login />
            )}
          </LeanspaceProvider18>
        </UserContext.Provider>
      </RefProvider>
    </div >
  ) : <div />;
}

export default App;
