import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { LsDashboard, LsGroundStation, LsSatellite } from './CustomVectors';

function SidebarButton({ icon, link }: { icon: ReactNode, link: string }) {
    return (
        <Link to={link} className="btn btn-link text-center fs-4 pt-0 my-1 mx-auto">
            {icon}
        </Link>
    );
}

export default function SideBar() {
    return (
        <div className="d-flex flex-column align-items-center" style={{ minHeight: '100vh', minWidth: '100px' }}>
            <SidebarButton icon={<LsDashboard color="white" />} link="/" />
            <SidebarButton icon={<LsSatellite color="white" />} link="/satellite/" />
            <SidebarButton icon={<LsGroundStation color="white" />} link="/stations/" />
        </div>
    );
}
