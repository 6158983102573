import React from "react";
const UserContext = React.createContext<{
  isLoggedIn: boolean;
  token?: string;
  login: (token: string) => void;
  env: string;
}>({
  isLoggedIn: false,
  token: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  login: () => {},
  env: "PROD",
});

export default UserContext;
