import React, { ReactElement, RefObject, useContext } from 'react';

const RefContext = React.createContext<RefObject<HTMLElement>>(null!);

/**
 * Uses RefContext to provide a ref of a parent to children
 */
function useParentRef(): RefObject<HTMLElement> {
    return useContext(RefContext);
}

interface ProvideRefProps {
    parentRef: RefObject<HTMLElement>
    children?: ReactElement<any, any>;
}

/**
 * Provides the ref of a parent to children
 * @param parentRef, ref to pass to children
 * @returns {React.Provider<RefObject<HTMLElement>>}
 */
function RefProvider({ children, parentRef }: ProvideRefProps) {
    return <RefContext.Provider value={parentRef}>{children}</RefContext.Provider>;
}

export { RefProvider, useParentRef };
