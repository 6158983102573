import React, { createContext, useContext } from "react";
import useSatellitesExternal from "../helpers/useSatellites";
import { Satellite } from "../helpers/useSatellites";

const SatellitesContext = createContext<Satellite[] | undefined>(undefined);

export function useSatellites() {
    return useContext(SatellitesContext);
}

export function SatellitesProvider({ children }: { children: React.ReactNode }) {
    const satellites = useSatellitesExternal();
    return (
        <SatellitesContext.Provider value={satellites}>
            {children}
        </SatellitesContext.Provider>
    );
}
