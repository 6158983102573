import { MdChevronRight, MdErrorOutline, MdOutlineMessage } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { useSatellites } from "../providers/SatellitesProvider";
import { SlackMessage } from "../providers/SlackProvider";

export default function NotificationBlock({
    message,
    showNavigation,
    onNavigate,
}: {
    message: SlackMessage,
    showNavigation?: boolean,
    onNavigate?: () => void,
}) {
    const satellites = useSatellites();
    const navigate = useNavigate();
    return (
        <div className="row my-3" key={message.ts}>
            <div className="col-1">
                {message.type === 'bot' ? (
                    <MdErrorOutline className="text-error" />
                ) : (
                    <MdOutlineMessage className="text-info" />
                )}
            </div>
            <div className="col-9">
                <div className="fw-600 fs-16">
                    {/* Replace to remove the hidden numbers that order monitors */}
                    {message.type === 'bot' ? message.monitorName.replace(/\d\d /, '') : message.username}
                </div>
                <div className="fw-400 fs-14 mb-1">
                    {message.content}
                </div>
                <div className="fw-400 fs-12 opacity-50">
                    SLACK | <ReactTimeAgo date={1000 * message.ts} />
                </div>
            </div>
            <div className="col-2">
                {showNavigation && message.type === 'bot' && (
                    <button className="btn px-2" onClick={() => {
                        const sat = satellites?.map(
                            (sat) => ({ sat, monitor: sat.monitors.find((m) => m.name === message.monitorName) })
                        ).find(({ sat, monitor }) => monitor !== undefined)?.sat;
                        if (sat) {
                            navigate(`/satellite/${sat.id}`);
                            if (onNavigate) onNavigate();
                        }
                    }}>
                        <MdChevronRight className="fs-24" />
                    </button>
                )}
            </div>
        </div>
    );
}
