import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Dashboard = lazy(() => import('./pages/Dashboard'));
const SatellitePage = lazy(() => import('./pages/SatellitePage'));
const GroundstationPage = lazy(() => import('./pages/GroundstationPage'));

export default function Router() {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="satellite">
                <Route index element={<SatellitePage />} />
                <Route path=":satelliteId" element={<SatellitePage />} />
            </Route>

            <Route path="stations">
                <Route index element={<GroundstationPage />} />
                <Route path=":stationId" element={<GroundstationPage />} />
            </Route>
        </Routes>
    );
}
